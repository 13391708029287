import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import BeFeaturedBanner from "../../components/beFeaturedBanner"
import FeaturingSpeakerCard from "../../components/featuringSpeakerCard"
import overtakeLogo from "../../images/logos/Overtake_Logo_KO.png"
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { motion } from "framer-motion"
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay])

function OvertakePage({ data }) {
  const site = (data || {}).site
  const sponsors = (data || {}).sponsors
  // console.log(site)
  // console.log(sponsors)

  const swiperBreakpoints = {
    768: {
      slidesPerView: 3,
    },
    1280: {
      slidesPerView: 4,
    },
    1920: {
      slidesPerView: 5,
    },
  }

  const swiperAutoplay = {
    delay: 2500,
    disableOnInteraction: false,
  }

  return (
    <Layout title="programs">
      <Seo title="Overtake Program" />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <section className="flex items-center justify-center py-32 w-full text-center bg-overtake-program-header bg-cover bg-center bg-no-repeat">
          <img
            className="px-16 w-auto lg:max-w-2xl"
            src={overtakeLogo}
            alt="Overtake Logo"
          />
        </section>
        <section className="flex flex-col justify-center px-8 py-16 w-full bg-white-left-starburst bg-cover bg-center bg-no-repeat space-y-8 md:flex-row md:space-x-8 md:space-y-0 lg:px-0 lg:py-24">
          <div className="max-w-2xl space-y-6">
            <h1 className="mb-4 text-gray-500 text-xl font-bold lg:text-3xl">
              About {site.edges[0].node.title}
            </h1>
            <p className="text-gray-500 whitespace-pre-line text-sm lg:text-base">
              {site.edges[0].node.description}
            </p>
            <div className="flex">
              <Link to="/resources?q=podcasts">
                <button
                  className="border-2
								font-bold
								text-gray-500
								border-secondary-200
								py-2
								px-4
								min-w-150
								rounded-full
								transition
								focus:outline-none
								hover:bg-secondary-200
								hover:text-white
								flex
								items-center"
                >
                  <svg
                    width="18"
                    height="16"
                    className="mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillrule="evenodd"
                      clipRule="evenodd"
                      d="M8.38268 1.07615C8.75636 1.23093 9 1.59557 9 2.00003V14C9 14.4045 8.75636 14.7691 8.38268 14.9239C8.00901 15.0787 7.57889 14.9931 7.29289 14.7071L3.58579 11H1C0.44772 11 0 10.5523 0 10V6.00003C0 5.44774 0.44772 5.00003 1 5.00003H3.58579L7.29289 1.29292C7.57889 1.00692 8.00901 0.92137 8.38268 1.07615Z"
                      className="fill-current"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M13.6569 0.928876C14.0475 0.538356 14.6806 0.538356 15.0712 0.928876C16.8797 2.73743 18.0001 5.2388 18.0001 7.99995C18.0001 10.7611 16.8797 13.2625 15.0712 15.071C14.6806 15.4615 14.0475 15.4615 13.6569 15.071C13.2664 14.6805 13.2664 14.0473 13.6569 13.6568C15.1058 12.208 16.0001 10.2094 16.0001 7.99995C16.0001 5.79053 15.1058 3.7919 13.6569 2.34309C13.2664 1.95257 13.2664 1.3194 13.6569 0.928876ZM10.8285 3.75731C11.219 3.36678 11.8522 3.36678 12.2427 3.75731C12.7686 4.28319 13.1977 4.90687 13.5004 5.59958C13.8221 6.33592 14.0001 7.14847 14.0001 7.99995C14.0001 9.6565 13.3274 11.1579 12.2427 12.2426C11.8522 12.6331 11.219 12.6331 10.8285 12.2426C10.438 11.8521 10.438 11.2189 10.8285 10.8284C11.5535 10.1034 12.0001 9.1048 12.0001 7.99995C12.0001 7.42922 11.8812 6.8889 11.6677 6.40032C11.4664 5.93958 11.1803 5.52327 10.8285 5.17152C10.438 4.781 10.438 4.14783 10.8285 3.75731Z"
                      className="fill-current"
                    />
                  </svg>
                  LISTEN NOW
                </button>
              </Link>
            </div>
          </div>
          <div className="space-y-4">
            <div className="flex flex-col justify-center p-8 w-full h-48 text-white bg-secondary-200 space-y-4 md:w-72">
              <h3 className="text-xl">Be a Program Sponsor</h3>
              <div>
                <Link to="/sponsorship">
                  <button
                    className="border-2
						font-bold
						text-white
						text-sm
						border-white
						py-2
						px-4
						min-w-150
						rounded-full
						transition
						focus:outline-none
						hover:bg-white
						hover:text-black"
                  >
                    LEARN MORE
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section className="flex flex-col justify-center w-full lg:flex-row">
          <div className="flex flex-col justify-center p-8 w-full text-center bg-gray-500 lg:w-1/4 lg:text-right">
            <h3 className="text-yellow-400 text-xl italic font-bold lg:text-2xl">
              Featuring
            </h3>
          </div>
          <div className="mx-auto p-4 w-full bg-gray-300 overflow-x-hidden">
            <Swiper slidesPerView={2} breakpoints={swiperBreakpoints}>
              {site &&
                site.edges[0].node.guests.map((guest, i) => (
                  <SwiperSlide key={i}>
                    <FeaturingSpeakerCard
                      key={i}
                      name={guest.name}
                      position={guest.position}
                      image={guest.image.asset.url}
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </section>
        <section className="py-8 w-full text-center bg-white lg:py-16">
          <h3 className="mb-4 text-gray-600 text-2xl font-bold lg:text-3xl">
            Our {new Date().getFullYear()} Sponsors
          </h3>
          <div className="fade-edges relative mx-auto w-full overflow-x-hidden lg:w-10/12">
            <Swiper
              slidesPerView={2}
              breakpoints={swiperBreakpoints}
              autoplay={swiperAutoplay}
              loop={true}
            >
              {sponsors &&
                sponsors.edges
                  .filter(
                    sponsor =>
                      sponsor.node.year.some(
                        element =>
                          element.value === new Date().getFullYear().toString()
                      ) &&
                      sponsor.node.sponsorTypes.some(
                        sponsor =>
                          sponsor.name === "The Overtake Podcast Series"
                      )
                  )
                  .map((sponsor, i) => (
                    <SwiperSlide key={i}>
                      <a
                        href={sponsor.node.company.url}
                        key={i}
                        target="_blank"
                        rel="noreferrer"
                        className="grayscale-effect relative block transition-all"
                      >
                        <img
                          src={sponsor.node.company.logo.asset.url}
                          alt={sponsor.node.company.name}
                          className="mx-auto w-32 h-16 object-contain object-center"
                        />
                      </a>
                    </SwiperSlide>
                  ))}
            </Swiper>
          </div>
        </section>
        <BeFeaturedBanner />
      </motion.div>
    </Layout>
  )
}

export default OvertakePage

export const query = graphql`
  {
    site: allSanityOvertakePodcast {
      edges {
        node {
          title
          guests {
            bio
            company {
              url
              name
              logo {
                asset {
                  url
                }
              }
            }
            name
            position
            image {
              asset {
                url
              }
            }
          }
          description
        }
      }
    }
    sponsors: allSanitySponsor {
      edges {
        node {
          company {
            logo {
              asset {
                url
              }
            }
            url
            name
          }
          year {
            value
          }
          sponsorTypes {
            name
          }
        }
      }
    }
  }
`
